.printContainer {
  font-family: Arial, sans-serif;
  font-size: larger;
  border: 2px solid #000;
  padding: 10px;
}

.section {
  border: 1px solid #000;
  padding: 7px;
  margin-bottom: 10px;
}

.Topgrid,
.contactGrid,
.foodGrid {
  width: 100%;
  border-collapse: collapse;
}

.Topgrid td,
.contactGrid td,
.foodGrid td,
.foodGrid th {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.mealTimeImage {
  width: 40px;
  height: auto;
}

@page {
  size: A4 landscape;
}

h1 {
  font-size: 2.75em;
  padding: 0;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  margin: 0;
}

h2 {
  font-size: 1.25em;
  margin: 0;
  padding: 0;
}